// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

$md-primary: (
    50: #fee6fe,
    100: #fcbffd,
    200: #fa95fb,
    300: #f76bf9,
    400: #f64bf8,
    500: #f42bf7,
    600: #f326f6,
    700: #f120f5,
    800: #ef1af3,
    900: #ec10f1,
    A100: #ffffff,
    A200: #feebff,
    A400: #fdb8ff,
    A700: #fc9eff,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$md-secondary: (
    50: #f6e1ff,
    100: #eab3ff,
    200: #dc80ff,
    300: #cd4dff,
    400: #c327ff,
    500: #b801ff,
    600: #b101ff,
    700: #a801ff,
    800: #a001ff,
    900: #9100ff,
    A100: #ffffff,
    A200: #f9f2ff,
    A400: #e0bfff,
    A700: #d4a6ff,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$nextpass-primary: mat.m2-define-palette($md-primary);
$nextpass-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$nextpass-warn: mat.m2-define-palette($md-primary);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$nextpass-theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $nextpass-primary,
            accent: $nextpass-accent,
            warn: $nextpass-warn,
        ),
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($nextpass-theme);

@import './assets/scss/main.scss';
@import './assets/scss/utils/mixins';

html,
body {
    @include text(14, 500);
    overflow: hidden;
}

.special-date {
    .mat-calendar-body-cell-content {
        background-color: $blue !important;
        color: $white;
    }
}
