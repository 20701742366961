// *****************************************************************************
//  Stylesheet Mixin
//
//  Table of Contents:
//    1. Fonts
//    2. Utils
//    3. Grid: Flex columns
//    4. Buttons
//    5. Form Alert
//    6. Toastr Alert
//    7. Frame
//    8. Block Index
//    9. Media queries
//   10. Titles
//   11. Texts
//   12. Links
//
// *****************************************************************************

@import 'variables';
// 1. Fonts
// -----------------------------------------------------------------------------
@mixin title-h1 {
    font-size: $font-title-size-l;
    font-weight: $font-weight-bold;
    line-height: $line-height-l;
}

@mixin title-h2 {
    font-size: $font-title-size-m;
    font-weight: $font-weight-bold;
    line-height: $line-height-m;
}

@mixin title-h3 {
    font-size: $font-title-size-m;
    font-weight: $font-weight-medium;
    line-height: $line-height-m;
}

@mixin title-h4 {
    font-size: $font-title-size-s;
    font-weight: $font-weight-bold;
    line-height: $line-height-s;
}

@mixin title-h5 {
    font-size: $font-size-m;
    font-weight: $font-weight-medium;
    line-height: $line-height-s;
}

@mixin default {
    font-size: $font-size-m;
    font-weight: $font-weight-regular;
    line-height: $line-height-s;
}

@mixin input-label {
    font-size: $font-size-m;
    font-weight: $font-weight-medium;
    line-height: $line-height-s;
}

@mixin input-value {
    font-size: $font-size-s;
    font-weight: $font-weight-medium;
    line-height: $line-height-xs;
}

// TODO: REFACTOR 😱
@mixin row-text() {
    font-size: $font-size-s;
    font-weight: $font-weight-regular;
    line-height: $line-height-s;
}

// TODO: REFACTOR 😱
@mixin modal-title {
    font-size: $font-title-size-m;
    font-weight: $font-weight-medium;
    color: $modal-title-config;
}

// 2. Utils
// -----------------------------------------------------------------------------
@mixin outline-effect($space, $radius) {
    content: '';
    position: absolute;
    top: $space;
    left: $space;
    right: $space;
    bottom: $space;
    border-radius: $radius;
}

@mixin ellipsis($width: 100%) {
    display: inline-block;
    max-width: $width;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
}

// 3. Grid: Flex columns
// -----------------------------------------------------------------------------
@mixin flex-row() {
    display: flex;
    width: 100%;
    flex-direction: row;
}

@mixin flex-column() {
    display: flex;
    width: 100%;
    flex-direction: column;
}

@mixin flex-row-auto() {
    display: flex;
    width: auto;
    flex-direction: row;
}

@mixin flex-column-auto() {
    display: flex;
    width: auto;
    flex-direction: column;
}

// 4. Buttons
// -----------------------------------------------------------------------------
@mixin btn-default {
    font-size: $font-size-m;
    font-weight: $font-weight-medium;
    line-height: $line-height-xs;
}

@mixin btn($color-text, $color-border, $color-background: $color-border) {
    border-color: $color-border;
    color: $color-text;
    background-color: $color-background;
}

// 5. Form Alert
// -----------------------------------------------------------------------------
@mixin alert($bg-color, $icon-color, $icon-size) {
    display: flex;
    padding: rem(20);
    background-color: $bg-color;
    border-radius: rem(8);
    line-height: rem(24);
    color: $pure-black;

    div {
        font-size: $font-size-m;
        margin-left: rem(10);
        font-weight: $font-weight-medium;
    }

    &::before {
        font-family: 'Material Icons Round';
        font-size: $icon-size;
        color: $icon-color;
    }
}

// 6. Toastr Alert
// -----------------------------------------------------------------------------
@mixin toastr($bg-color, $icon-color) {
    position: absolute;
    top: 6.5rem;
    left: calc(50vw - 20rem);

    background-color: $bg-color !important;
    color: $pure-black;
    border-radius: rem(8);
    min-height: rem(54) !important;
    width: rem(400);

    span {
        margin-left: 3.5rem;
        font-size: 1.6rem;
        margin-top: rem(8);
        margin-bottom: rem(8);
    }

    &::before {
        position: absolute;
        top: 2rem;
        font-size: rem(23);
        font-family: 'Material Icons Round';
        font-weight: normal;
        font-style: normal;
        color: $icon-color;
    }
}

// 7. Frame
// -----------------------------------------------------------------------------
@mixin frame($paddingTopBottom: 10, $paddingLeftRight: 10) {
    border-radius: 0 0 rem(8) rem(8);
    border-left: 1px solid $separator;
    border-right: 1px solid $separator;
    border-bottom: 1px solid $separator;
    padding: rem($paddingTopBottom) rem($paddingLeftRight);
}

// 8. Block Index
// -----------------------------------------------------------------------------
@mixin block-zindex($index: 1) {
    position: relative;
    z-index: $index;
}

// 9. Media queries
//-----------------------------------

@mixin max-width($breakpoint) {
    @each $name, $value in $breakpoints {
        @if $breakpoint == $name {
            @media (max-width: $value) {
                @content;
            }
        }
    }
}

@mixin min-width($breakpoint) {
    @each $name, $value in $breakpoints {
        @if $breakpoint == $name {
            @media (min-width: $value) {
                @content;
            }
        }
    }
}

/* 10. Titles
--------------------------------------------------------------------------------
Examples:
    Regular: @include text(14, 400);
    Medium: @include text(14, 500);
    Bold: @include text(14, 700);
    ExtraBold: @include text(14, 800);
*/
@mixin title($size, $weight) {
    font-family: 'Manrope', sans-serif;
    $line-height: $size + 8;
    font-size: #{$size}px;
    line-height: #{$line-height}px;
    font-weight: $weight;
    font-style: normal;
}

/* 11. Texts
--------------------------------------------------------------------------------
Examples:
    Regular: @include text(14, 400);
    Medium: @include text(14, 500);
    Bold: @include text(14, 700);
    ExtraBold: @include text(14, 800);
*/

@mixin text($size, $weight) {
    $line-height: $size + 8;
    font-family: 'Manrope', sans-serif;
    font-weight: $weight;
    font-size: #{$size}px;
    line-height: #{$line-height}px;
}

/* 12. Links
--------------------------------------------------------------------------------

Examples:
    Medium: @include link(14, 500);
    ExtraBold: @include link(14, 800);
*/
@mixin link($size, $weight) {
    $line-height: $size + 8;
    font-family: 'Manrope', sans-serif;
    font-weight: $weight;
    font-size: #{$size}px;
    line-height: #{$line-height}px;
}

@mixin svg($width, $filter-color) {
    width: #{$width}px;
    height: #{$width}px;
    filter: $filter-color;
}
