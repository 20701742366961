$breakpoint-mobile-min: 480px;
$breakpoint-tablet-min: 768px;
$breakpoint-desktop-min: 1024px;
$breakpoint-desktop-max: 1440px;

// Breakpoints
$breakpoints: (
    phone: 479px,
    phone-landscape: 767px,
    tablet: 1023px,
    desktop: 1199px,
    desktop-large: 1439px,
);

// COLORS
$black: #0b0c0c;
$white: #ffffff;
$background-color: #30428b;
$background-color-gray: #f5f5f5;
$info: #e2e7fd;
$info-color: #e2e7fd;
$gray: #d8d8d8;
$map-coverage: #76c19d;
$map-not-coverage: #acd6c4;

// Primary colors
$white: #ffffff;
$black: #0b0c0c;
$blue: #7089f4;
$green: #99cdb6;
$purple: #e8cefe;
$red: #fdc3c2;

// Secondary colors
$dark-blue: #30428b;
$dark-green: #22332b;
$dark-purple: #3b0082;
$dark-red: #7c150d;

// Shades
$blue-shade: #a9b8f8;
$green-shade: #add7c5;
$purple-shade: #edd8fe;
$red-shade: #fdcfce;
$dark-blue-shade: #5968a2;
$dark-green-shade: #4e5c55;
$dark-purple-shade: #62339b;
$dark-red-shade: #96443d;

// Blue shades
$blue-shade-1: #8da1f6;
$blue-shade-2: #a9b8f8;
$blue-shade-3: #c6d0fb;
$blue-shade-4: #e2e7fd;
$blue-shade-5: #edf0fe;

// Blue shade Dark::after
$blue-shade-dark-1: #5a6ec3;
$blue-shade-dark-2: #30428b;

// Dark Blue shades
$dark-blue-shade-1: #5968a2;
$dark-blue-shade-2: #838eb9;
$dark-blue-shade-3: #acb3d1;
$dark-blue-shade-4: #d6d9e8;

// Green shades
$green-shade-1: #add7c5;
$green-shade-2: #c2e1d3;
$green-shade-3: #d6ebe2;
$green-shade-4: #ebf5f0;
$green-shade-dark-1: #739a89;
$green-shade-dark-2: #4d675b;

// Dark Green shades
$dark-green-shade-1: #4e5c55;
$dark-green-shade-2: #7a8580;
$dark-green-shade-3: #a7adaa;
$dark-green-shade-4: #d3d6d5;

// Purple shades
$purple-shade-1: #edd8fe;
$purple-shade-2: #f1e2fe;
$purple-shade-3: #f6ebff;
$purple-shade-4: #faf5ff;

// Dark Purple shades
$dark-purple-shade-1: #62339b;
$dark-purple-shade-2: #8966b4;
$dark-purple-shade-3: #b199cd;
$dark-purple-shade-4: #d8cce6;

// Red shades
$red-shade-1: #fdcfce;
$red-shade-2: #fedbda;
$red-shade-3: #fee7e7;
$red-shade-4: #fff3f3;

// Dark Red shades
$dark-red-shade-1: #96443d;
$dark-red-shade-2: #b0736e;
$dark-red-shade-3: #cba19e;
$dark-red-shade-4: #e5d0cf;

// Ui colors
$disabled: #747678;
$light-gray-00: #f5f5f5;
$light-gray-0: #e5e5e5;
$light-gray-1: #d8d8d8;
$light-gray-2: #bebebe;
$light-gray-3: #909193;
$light-gray-4: #747678;
$error: #c71e11;
$success: #456558;

//Filters
// $blue-shade-dark-1-filter: invert(45%) sepia(95%) saturate(328%) hue-rotate(190deg) brightness(82%) contrast(91%);
$blue-shade-dark-1-filter: invert(40%) sepia(85%) saturate(345%) hue-rotate(191deg) brightness(93%) contrast(100%);
$blue-shade-dark-2-filter: invert(24%) sepia(30%) saturate(1402%) hue-rotate(192deg) brightness(100%) contrast(98%);
$blue-shade-2-filter: invert(71%) sepia(15%) saturate(942%) hue-rotate(193deg) brightness(101%) contrast(95%);
$green-shade-dark-1-filter: invert(60%) sepia(13%) saturate(614%) hue-rotate(102deg) brightness(93%) contrast(86%);
$dark-red-shade-filter: invert(28%) sepia(13%) saturate(4350%) hue-rotate(326deg) brightness(95%) contrast(77%);
$black-filter: invert(5%) sepia(12%) saturate(189%) hue-rotate(131deg) brightness(97%) contrast(100%);
$white-filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(239deg) brightness(101%) contrast(105%);
$error-filter: invert(17%) sepia(61%) saturate(4612%) hue-rotate(355deg) brightness(91%) contrast(95%);
$light-gray-3-filter: invert(63%) sepia(6%) saturate(91%) hue-rotate(182deg) brightness(91%) contrast(87%);
